
import { defineComponent, computed } from "vue";
import { getAuth, signOut } from "firebase/auth";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const i18n = useI18n();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/france.svg",
        name: "French",
      },
    };
    const expertProfileImageUrl = computed(() => {
      return store.getters["ExpertModule/getExpertProfileImageUrl"];
    });
    const founderProfileImageUrl = computed(() => {
      return store.getters["FounderModule/getFounderProfileImageUrl"];
    });
    const logout = () => {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          localStorage.setItem("logout-event", "logout" + Math.random());
          store.commit("UserModule/CLEAR_USER_STORE");
          store.commit("ExpertModule/CLEAR_EXPERT_STATE");
          store.commit("FounderModule/CLEAR_FOUNDER_STATE");
          router.push({ name: "sign-in" });
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      logout,
      setLang,
      currentLanguage,
      getAuth,
      currentLangugeLocale,
      countries,
      expertProfileImageUrl,
      founderProfileImageUrl,
    };
  },
});
