import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cc4ea6f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "menu user-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3" }
const _hoisted_3 = { class: "menu-content d-flex align-items-center px-3" }
const _hoisted_4 = { class: "symbol symbol-50px me-5" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = {
  class: "fw-bolder d-flex align-items-center fs-5",
  style: {"overflow-wrap":"anywhere"}
}
const _hoisted_8 = {
  class: "fw-bold text-muted text-primary fs-7",
  style: {"overflow-wrap":"anywhere"}
}
const _hoisted_9 = { class: "menu-item px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            alt: "Logo",
            src: 
              _ctx.expertProfileImageUrl
                ? _ctx.expertProfileImageUrl
                : _ctx.founderProfileImageUrl
                ? _ctx.founderProfileImageUrl
                : '/media/avatars/150-26.jpg'
            
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getAuth().currentUser ? _ctx.getAuth().currentUser.displayName : ""), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getAuth().currentUser ? _ctx.getAuth().currentUser.email : ""), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout())),
        class: "menu-link px-5"
      }, " Sign Out ")
    ])
  ]))
}