import RequiredUploadsEnum from "./RequiredUploadsEnum";

const userCanVisitRouteBasedOnAccountType = async (to, store) => {
  const expert = await store.getters["ExpertModule/getExpert"];
  const founder = await store.getters["FounderModule/getFounder"];
  const userAccountType = expert.id ? "expert" : founder.id ? "founder" : null;
  return !!(
    userAccountType &&
    to.meta.accountAccess &&
    to.meta.accountAccess.includes(userAccountType)
  );
};

const getFileTypeUrlFromFile = (fileType) => {
  if (
    fileType === RequiredUploadsEnum.VENTURE_PITCH ||
    fileType === RequiredUploadsEnum.FINANCIAL_PLAN
  ) {
    return "/media/svg/files/pdf.svg";
  } else if (
    fileType === RequiredUploadsEnum.DATA_REQUEST ||
    fileType === RequiredUploadsEnum.SEVEN_FOUNDERS_FORECAST
  ) {
    return "/media/svg/files/doc.svg";
  }
  return "/media/svg/files/folder-document.svg";
};

export { userCanVisitRouteBasedOnAccountType, getFileTypeUrlFromFile };
