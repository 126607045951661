const FounderMenuConfig = [
  {
    pages: [
      // {
      //   heading: "Home",
      //   route: "/dashboard",
      //   svgIcon: "media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      // },
      // {
      //   heading: "Deal",
      //   route: "/deal",
      //   svgIcon: "media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      // },
      {
        heading: "Pitch",
        route: "/pitch",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Needs",
        route: "/needs",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Profile",
        route: "/founder-info",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default FounderMenuConfig;
