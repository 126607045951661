const ExpertMenuConfig = [
  {
    pages: [
      // {
      //   heading: "Home",
      //   route: "/dashboard",
      //   svgIcon: "media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      // },
      {
        heading: "Profile",
        route: "/profile",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      // {
      //   heading: "Projects",
      //   route: "/projects",
      //   svgIcon: "media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      // },
    ],
  },
];

export default ExpertMenuConfig;
