<template>
  <KTLoader v-if="loaderEnabled" :logo="loaderLogo"></KTLoader>
  <Navbar>
    <ExpertNavMenu v-if="user.role === 2" />
    <FounderNavMenu v-else />
  </Navbar>
  <div
    id="kt_content"
    class="content d-flex flex-column flex-column-fluid"
    v-loading.fullscreen.lock="isDataLoading"
  >
    <div
      id="kt_content_container"
      class="mt-10"
      :class="{
        'container-fluid': contentWidthFluid,
        'container-xxl': !contentWidthFluid,
      }"
    >
      <router-view />
    </div>
  </div>
  <KTScrollTop></KTScrollTop>
  <KTUserMenu></KTUserMenu>
</template>

<script>
import { defineComponent, computed, onMounted, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import HtmlClass from "@/core/services/LayoutService";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
import { MenuComponent } from "@/assets/ts/components";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import { getLoggedInUserWithRelations } from "@/api/user.api";
import store from "@/store/store";
import Navbar from "@/common/components/Navbar.vue";
import ExpertNavMenu from "@/expert/components/ExpertNavMenu.vue";
import FounderNavMenu from "@/founder/components/FounderNavMenu.vue";
import { getAuth, signOut } from "firebase/auth";
import { userCanVisitRouteBasedOnAccountType } from "../common/helpers/helpers";

export default defineComponent({
  name: "Layout",
  components: {
    FounderNavMenu,
    ExpertNavMenu,
    Navbar,
    KTScrollTop,
    KTUserMenu,
    KTLoader,
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const {
        data: { data },
      } = await getLoggedInUserWithRelations();
      await store.dispatch("UserModule/setUserRole", data.role);
      await store.dispatch("UserModule/setUser", data);
      await store.dispatch("UserModule/setUserId", data.id);

      //
      if (!(await userCanVisitRouteBasedOnAccountType(to, store))) {
        next("/dashboard");
      }
      //
    } catch ({
      response: {
        data: { statusCode, message },
      },
    }) {
      if (Number(statusCode) === 404) {
        next("/account-selection");
        return;
      }
      if (Number(statusCode) === 403) {
        const auth = getAuth();
        await signOut(auth)
          .then(() => {
            store.commit("UserModule/CLEAR_USER_STORE");
            store.commit("ExpertModule/CLEAR_EXPERT_STATE");
            store.commit("FounderModule/CLEAR_FOUNDER_STATE");
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            next("/sign-in");
            return;
          });
      }
    }
    next();
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isDataLoading = computed(
      () => store.getters["ExpertModule/isDataLoading"]
    );
    const user = computed(() => store.getters["UserModule/getUser"]);

    // show page loading
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      nextTick(() => {
        reinitializeComponents();
      });

      // Simulate the delay page loading
      // setTimeout(() => {
      //   // Remove page loader after some time
      //   store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      // }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      isDataLoading,
      user,
    };
  },
});
</script>
