
import { computed, defineComponent, onMounted } from "vue";
import { getAuth } from "firebase/auth";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { useStore } from "vuex";

export default defineComponent({
  name: "Navbar",
  props: {
    title: String,
  },
  components: {
    KTUserMenu,
  },
  setup() {
    const store = useStore();

    onMounted(async () => {
      try {
        await store.dispatch("ExpertModule/setDataLoading", true);
        await store.dispatch("ExpertModule/fetchSkillsWithGroups");
      } catch (e) {
        console.error(e);
      } finally {
        await store.dispatch("ExpertModule/setDataLoading", false);
      }
    });
    const expertProfileImageUrl = computed(() => {
      return store.getters["ExpertModule/getExpertProfileImageUrl"];
    });
    const founderProfileImageUrl = computed(() => {
      return store.getters["FounderModule/getFounderProfileImageUrl"];
    });
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      getAuth,
      expertProfileImageUrl,
      founderProfileImageUrl,
    };
  },
});
